import { getItem } from '../utils/storage';

const FUNCTION_BASE_URL =
  'https://gemmed-unctions-hvhludohph.ap-northeast-2.fcapp.run'; // PROD API

// const FUNCTION_BASE_URL =
//   'https://gemmed-unctions-lewhoxyzwh.ap-northeast-2.fcapp.run'; // TEST API

interface FilterItem {
  yearFrom?: number;
  fundCategory?: string;
  projectCategory?: string;
  subject?: string;
}

interface SearchItem {
  query: string;
  filter?: FilterItem;
}

export const getSummaryQueryTerms = async (params: { query: string }) => {
  const token = getItem('token');
  const option = {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : undefined,
    },
  };
  return fetch(`${FUNCTION_BASE_URL}/query/terms`, option);
};

export const fetchPreviousPapers = async (params: { paper: any }) => {
  const token = getItem('token');
  const option = {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : undefined,
    },
  };
  return fetch(`${FUNCTION_BASE_URL}/paper/previous_papers`, option);
};

export const getSummaryConcept = async (params: { query: string }) => {
  const token = getItem('token');
  const option = {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : undefined,
    },
  };
  return fetch(`${FUNCTION_BASE_URL}/query/concept`, option);
};

export const getSummaryBackground = async (params: { query: string }) => {
  const token = getItem('token');
  const option = {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : undefined,
    },
  };
  return fetch(`${FUNCTION_BASE_URL}/query/background`, option);
};

export const getRelatedSearch = async (params: {
  answer: string;
  queryZh: string;
}) => {
  const token = getItem('token');
  const option = {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : undefined,
    },
  };
  return fetch(`${FUNCTION_BASE_URL}/query/related_search`, option);
};

export const getBulletPointsExpansion = async (params: {
  targetBltptTitle: string;
  allTitles: string[];
  papers: any[];
  rawQuery: string;
}) => {
  const token = getItem('token');
  const option = {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : undefined,
    },
  };
  return fetch(`${FUNCTION_BASE_URL}/bltpt/expansion`, option);
};

export const getBulletPointsLiteraturePreview = async (params: {
  bltpt: string;
  subtitles: string;
  papers: any[];
}) => {
  const token = getItem('token');
  const option = {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : undefined,
    },
  };
  return fetch(`${FUNCTION_BASE_URL}/bltpt/literature_review`, option);
};

// 多步搜索
export const getPartPedia = async (params: SearchItem, lang: string) => {
  let path = '/search/paper_en';
  if (lang === 'zh-cn') {
    path = '/search/paper_zh';
  }
  const token = getItem('token');
  const option = {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : undefined,
    },
  };
  try {
    umami.track('search', {
      query: params.query,
      type: lang,
    });
  } catch {}
  return fetch(`${FUNCTION_BASE_URL}${path}`, option);
};

export const getFund = async (params: SearchItem) => {
  const token = getItem('token');
  const option = {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : undefined,
    },
  };
  try {
    umami.track('search', {
      query: params.query,
      type: 'fund_cn',
    });
  } catch {}
  return fetch(`${FUNCTION_BASE_URL}/search/fund_zh`, option);
};

export const getSummaryBulletPoints = async (params: {
  papers: any[];
  queryZh: string;
  rawQuery: string;
}) => {
  const token = getItem('token');
  const option = {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : undefined,
    },
  };
  return fetch(`${FUNCTION_BASE_URL}/bltpt/gen`, option);
};

export const getSummaryAnalysis = async (params: {
  papers: any[];
  queryZh: string;
  rawQuery: string;
}) => {
  const token = getItem('token');
  const option = {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : undefined,
    },
  };
  return fetch(`${FUNCTION_BASE_URL}/bltpt/analysis`, option);
};

export const getResponsePedia = async (params: {
  papers: {
    id: string;
  }[];
  queryZh: string;
}) => {
  const token = getItem('token');
  const option = {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : undefined,
    },
  };
  return fetch(`${FUNCTION_BASE_URL}/paper/response`, option);
};

export const fetchAbstractAndTranslation = async (paper: any) => {
  // Assuming the token is stored and should be included in the request if available
  const token = getItem('token');
  const apiUrl = `${FUNCTION_BASE_URL}/paper/abstract_and_translation`;
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : undefined,
    },
    body: JSON.stringify({ paper }),
  };

  return fetch(apiUrl, options);
};

export const fetchJournalInfo = async (paper: any) => {
  const token = getItem('token');
  const option = {
    method: 'POST',
    body: JSON.stringify({ paper }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : undefined,
    },
  };
  return fetch(`${FUNCTION_BASE_URL}/paper/journal_info`, option);
};

export const fetchReadingGuide = async (paper: any) => {
  const token = getItem('token');
  const option = {
    method: 'POST',
    body: JSON.stringify({ paper }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : undefined,
    },
  };
  return fetch(`${FUNCTION_BASE_URL}/paper/reading_guide`, option);
};

export const fetchFundSimiliar = async (paper: any) => {
  // Assuming the token is stored and should be included in the request if available
  const token = getItem('token');
  const apiUrl = `${FUNCTION_BASE_URL}/paper/similar_fund`;
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : undefined,
    },
    body: JSON.stringify({ paper }),
  };

  return fetch(apiUrl, options);
};

export const fetchSimiliar = async (paper: any) => {
  // Assuming the token is stored and should be included in the request if available
  const token = getItem('token');
  const apiUrl = `${FUNCTION_BASE_URL}/paper/similar_papers`;
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : undefined,
    },
    body: JSON.stringify({ paper }),
  };

  return fetch(apiUrl, options);
};

export const fetchResponses = async (params) => {
  const token = getItem('token');
  const apiUrl = `${FUNCTION_BASE_URL}/paper/response`;
  const options = {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : undefined,
    },
  };

  return fetch(apiUrl, options);
};
