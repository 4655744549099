import { action, computed, flow, makeObservable, observable } from 'mobx';
import {
  getFund,
  getPartPedia,
  getRelatedSearch,
  getResponsePedia,
  getSummaryAnalysis,
  getSummaryBackground,
  getSummaryBulletPoints,
  getSummaryConcept,
  getSummaryQueryTerms,
} from '../service';
import {
  handlePopoverContentExtension,
  IPopoverItem,
} from '../utils/dataHandler';

export enum SearchMode {
  EN = 'en',
  ZH_CN = 'zh-cn',
  FUND = 'fund',
}

export enum SortMode {
  DEFAULT = 'default',
  RELEVANCE = 'relevance',
  TIME = 'time',
  LEVEL = 'level',
  QUOTE = 'quote',
}

export interface IPopoverInfo {
  expensionPopoverList: IPopoverItem[];
  key: number;
  popoverList: IPopoverItem[];
  paperList?: any[];
  desc?: IPopoverItem;
  title?: IPopoverItem;
}

export interface SearchFilter {
  yearFrom: number | undefined;
  fundCategory: string | undefined;
  projectCategory: string | undefined;
  subject: string | undefined;
}

export interface QueryContext {
  concept: string;
  background: string;
  terms: string;
  relatedSearch: string[];
}

function handlePaperList(
  content: {
    refs: string;
    summary: string;
    title: string;
  },
  papers: any[]
) {
  const matches = (content.refs.match(/\[(.*?)\]/g) || []).map((match) =>
    match.slice(1, -1)
  );
  const list = matches.reduce<IPopoverItem[]>((arr, element, index) => {
    // @ts-ignore
    if (matches.includes(element)) {
      const id = element.replace(/^\[(.+)\]$/, '$1');
      const paper = papers.find((item) => item.id === id);

      return [...arr, paper];
    }
  }, []);

  return list;
}

function handlePopoverContent(
  content: {
    refs: string;
    summary: string;
    title: string;
  },
  papers: any[]
) {
  const matches = (content.refs.match(/\[(.*?)\]/g) || []).map((match) =>
    match.slice(1, -1)
  );
  const limit = 3;
  const limitMatches = matches.slice(0, limit);
  const list = limitMatches.reduce<IPopoverItem[]>((arr, element, index) => {
    // @ts-ignore
    if (matches.includes(element)) {
      const id = element.replace(/^\[(.+)\]$/, '$1');
      const paper = papers.find((item) => item.id === id);
      const first_author_name = paper?.authors[0] || '';
      const author_last_name =
        first_author_name.split(' ')[first_author_name.split(' ').length - 1];
      const year = paper?.year || '';

      let template = `(${author_last_name}, ${year}) `;
      if (index === limitMatches.length - 1 && matches.length > 4) {
        template = `(${author_last_name}, ${year}) ...等${matches.length}篇 `;
      }
      return [
        ...arr,
        {
          text: template,
          id,
          key: Math.random(),
          type: 'popover',
          isVisible: false,
        },
      ];
    }
    return [
      ...arr,
      {
        text: element,
        key: Math.random(),
        type: 'text',
        isVisible: false,
      },
    ];
  }, []);

  return {
    contentList: list,
    title: {
      text: content.title,
      key: Math.random(),
      type: 'text',
      isVisible: false,
    },
    desc: {
      text: content.summary,
      key: Math.random(),
      type: 'text',
      isVisible: false,
    },
  };
}

const initPopoverContent = (
  contentList: {
    refs: string;
    summary: string;
    title: string;
  }[],
  papers: any[]
) => {
  const formattedContentList: IPopoverInfo[] = [];
  contentList.forEach((content) => {
    const info = {
      key: Math.random(),
      popoverList: [],
      paperList: [],
      expensionPopoverList: [],
      desc: undefined,
      title: undefined,
    };
    const popoverContent = handlePopoverContent(content, papers);
    info.popoverList = popoverContent.contentList;
    info.paperList = handlePaperList(content, papers);
    info.desc = popoverContent.desc;
    info.title = popoverContent.title;
    formattedContentList.push(info);
  });
  return formattedContentList;
};

class MainState {
  isInitialed = false;
  mode: SearchMode;
  sortMode: SortMode = SortMode.DEFAULT;
  pageIndex = 1;
  pageSize = 10;
  question: string = '';
  idFilterText: string = '';
  paperIdFilter: string[] = [];
  isLoadingZhPapers: boolean = false;
  isLoadingEnPapers: boolean = false;
  isLoadingFundPapers: boolean = false;
  isLoadingSummaryConcept = false;
  isLoadingSummaryQueryTerms = false;
  isLoadingSummaryBackground = false;
  isLoadingSummaryAnalysis = false;
  isLoadingSummaryBulletPoints = false;
  searchFilter: SearchFilter = {
    yearFrom: undefined,
    fundCategory: undefined,
    projectCategory: undefined,
    subject: undefined,
  };
  queryContext: QueryContext = {
    concept: '',
    background: '',
    terms: '',
    relatedSearch: [],
  };
  paperInfo: {
    papers: any[];
    queryZh: string;
  };
  paperZHInfo: {
    papers: any[];
    queryZh: string;
  };
  fundInfo: {
    papers: any[];
    queryZh: string;
  };
  summaryInfo: {
    summary: string;
    bulletPoints: IPopoverInfo[];
    bulletPointsPrefix: string;
  };
  summaryZHInfo: {
    summary: string;
    bulletPoints: IPopoverInfo[];
    bulletPointsPrefix: string;
  };
  summaryFundInfo: {
    summary: string;
    bulletPoints: IPopoverInfo[];
    bulletPointsPrefix: string;
  };
  isLoadingFaq = false;

  constructor() {
    this.mode = SearchMode.EN;
    this.paperInfo = {
      papers: [],
      queryZh: '',
    };
    this.paperZHInfo = {
      papers: [],
      queryZh: '',
    };
    this.fundInfo = {
      papers: [],
      queryZh: '',
    };
    this.summaryInfo = {
      summary: '',
      bulletPoints: [],
      bulletPointsPrefix: '',
    };
    this.summaryZHInfo = {
      summary: '',
      bulletPoints: [],
      bulletPointsPrefix: '',
    };
    this.summaryFundInfo = {
      summary: '',
      bulletPoints: [],
      bulletPointsPrefix: '',
    };

    makeObservable(this, {
      isInitialed: observable,
      mode: observable,
      sortMode: observable,
      pageIndex: observable,
      pageSize: observable,
      question: observable,
      showPapers: computed,
      paperInfo: observable,
      paperZHInfo: observable,
      fundInfo: observable,
      summaryInfo: observable,
      summaryZHInfo: observable,
      summaryFundInfo: observable,
      isLoadingFaq: observable,
      togglePopoverVisible: action,
      isLoadingPapers: computed,
      isLoadingZhPapers: observable,
      isLoadingEnPapers: observable,
      isLoadingFundPapers: observable,
      isPapersEmptyErrorVisible: computed,
      isLoadingSummaryConcept: observable,
      isLoadingSummaryQueryTerms: observable,
      isLoadingSummaryBackground: observable,
      isLoadingSummaryAnalysis: observable,
      isLoadingSummaryBulletPoints: observable,
      queryContext: observable,
      updateExpensionText: action,
      updateResponsePedia: action,
      fetchFund: flow,
      fetchSearchPapers: flow,
      fetchRelatedSearch: flow,
      fetchSummaryConcept: flow,
      fetchSummaryBulletPoints: flow,
      fetchSummaryAnalysis: flow,
      fetchSummaryBackground: flow,
      fetchSummaryQueryTerms: flow,
      fetchResponsePedia: flow,
      fetchZHPapers: flow,
      fetchEnPapers: flow,
      updateQuestion: action,
      changeMode: action,
      changeSortMode: action,
      changePageIndex: action,
      setPaperIdFilter: action,
      paperIdFilter: observable,
      idFilterText: observable,
      setIdFilterText: action,
      searchFilter: observable,
      setFundPaperYearFilter: action,
      setFundPaperTypeFilter: action,
      setFundPaperProjectFilter: action,
      setFundPaperSubjectFilter: action,
      currentPaperTotal: computed,
      fetchFilterFundPapers: flow,
      initfetchSearchPapers: flow,
      killFundPapers: action,
      hideAllPopover: action,
    });
  }

  public setFundPaperYearFilter(yearFrom: number | undefined) {
    this.searchFilter.yearFrom = yearFrom;
  }

  public setFundPaperTypeFilter(type: string | undefined) {
    this.searchFilter.fundCategory = type;
  }

  public setFundPaperProjectFilter(project: string | undefined) {
    this.searchFilter.projectCategory = project;
  }

  public setFundPaperSubjectFilter(subject: string | undefined) {
    this.searchFilter.subject = subject;
  }

  public get isPapersEmptyErrorVisible() {
    switch (this.mode) {
      case SearchMode.EN:
        return (
          this.paperInfo.papers.length === 0 &&
          !this.isLoadingPapers &&
          this.question.length > 0
        );
      case SearchMode.ZH_CN:
        return (
          this.paperZHInfo.papers.length === 0 &&
          !this.isLoadingPapers &&
          this.question.length > 0
        );
      case SearchMode.FUND:
        return (
          this.fundInfo.papers.length === 0 &&
          !this.isLoadingPapers &&
          this.question.length > 0
        );
    }
  }

  public get isLoadingPapers() {
    return (
      this.isLoadingEnPapers ||
      this.isLoadingZhPapers ||
      this.isLoadingFundPapers
    );
  }

  public updateQuestion(question: string) {
    this.question = question;
    this.paperInfo = {
      papers: [],
      queryZh: '',
    };
    this.paperZHInfo = {
      papers: [],
      queryZh: '',
    };
    this.fundInfo = {
      papers: [],
      queryZh: '',
    };
    this.summaryInfo = {
      summary: '',
      bulletPoints: [],
      bulletPointsPrefix: '',
    };
    this.summaryZHInfo = {
      summary: '',
      bulletPoints: [],
      bulletPointsPrefix: '',
    };
    this.summaryFundInfo = {
      summary: '',
      bulletPoints: [],
      bulletPointsPrefix: '',
    };
    this.queryContext.relatedSearch = [];
    this.isInitialed = false;
    this.pageIndex = 1;
  }

  public get showSummary() {
    switch (this.mode) {
      case SearchMode.EN:
        return this.summaryInfo;
      case SearchMode.ZH_CN:
        return this.summaryZHInfo;
      case SearchMode.FUND:
        return this.summaryFundInfo;
      default:
        return this.summaryZHInfo;
    }
  }

  public setIdFilterText(text: string) {
    this.idFilterText = text;
  }

  public get currentPaperTotal() {
    const theSet = new Set(this.paperIdFilter);
    let newList = [];
    switch (this.mode) {
      case SearchMode.EN:
        newList = this.paperInfo.papers;
        break;
      case SearchMode.ZH_CN:
        newList = this.paperZHInfo.papers;
        break;
      case SearchMode.FUND:
        newList = this.fundInfo.papers;
        break;
      default:
        return 0;
    }

    if (theSet.size > 0) {
      newList = newList.filter((item) => {
        return theSet.has(item.id);
      });
    }
    return newList.length;
  }

  public get showPapers() {
    let newList = [];
    switch (this.mode) {
      case SearchMode.EN:
        newList = this.paperInfo.papers;
        break;
      case SearchMode.ZH_CN:
        newList = this.paperZHInfo.papers;
        break;
      case SearchMode.FUND:
        newList = this.fundInfo.papers;
        break;
      default:
        return [];
    }
    switch (this.sortMode) {
      case 'time':
        newList = newList.slice().sort((a, b) => b.year - a.year);
        break;
      case 'relevance':
        newList = newList.slice().sort((a, b) => b.relevance - a.relevance);
        break;
      case 'quote':
        newList = newList
          .slice()
          .sort((a, b) => b.citationCount - a.citationCount);

        break;
    }
    const theSet = new Set(this.paperIdFilter);
    if (theSet.size > 0) {
      newList = newList.filter((item) => {
        return theSet.has(item.id);
      });
    }
    return newList.slice(
      (this.pageIndex - 1) * this.pageSize,
      this.pageIndex * this.pageSize
    );
  }

  public changeMode(mode: SearchMode) {
    this.setIdFilterText('');
    this.paperIdFilter = [];
    this.mode = mode;
  }

  public changeSortMode(sortMode: SortMode) {
    this.sortMode = sortMode;
  }

  public changePageIndex(pageIndex: number) {
    this.pageIndex = pageIndex;
  }

  public hideAllPopover() {
    const bulletPoints = this.summaryInfo.bulletPoints;
    bulletPoints.forEach((item) => {
      [...item.popoverList, ...item.expensionPopoverList].forEach((element) => {
        element.isVisible = false;
      });
    });

    const bulletPointsZH = this.summaryZHInfo.bulletPoints;
    bulletPointsZH.forEach((item) => {
      [...item.popoverList, ...item.expensionPopoverList].forEach((element) => {
        element.isVisible = false;
      });
    });

    const bulletPointsFund = this.summaryFundInfo.bulletPoints;
    bulletPointsFund.forEach((item) => {
      [...item.popoverList, ...item.expensionPopoverList].forEach((element) => {
        element.isVisible = false;
      });
    });

    this.summaryInfo.bulletPoints = [...bulletPoints];
    this.summaryZHInfo.bulletPoints = [...bulletPointsZH];
    this.summaryFundInfo.bulletPoints = [...bulletPointsFund];
  }

  public togglePopoverVisible(key: number, isVisible: boolean) {
    const bulletPoints = this.summaryInfo.bulletPoints;
    bulletPoints.forEach((item) => {
      [...item.popoverList, ...item.expensionPopoverList].forEach((element) => {
        if (element.key === key) {
          element.isVisible = isVisible;
        }
      });
    });

    const bulletPointsZH = this.summaryZHInfo.bulletPoints;
    bulletPointsZH.forEach((item) => {
      [...item.popoverList, ...item.expensionPopoverList].forEach((element) => {
        if (element.key === key) {
          element.isVisible = isVisible;
        }
      });
    });

    const bulletPointsFund = this.summaryFundInfo.bulletPoints;
    bulletPointsFund.forEach((item) => {
      [...item.popoverList, ...item.expensionPopoverList].forEach((element) => {
        if (element.key === key) {
          element.isVisible = isVisible;
        }
      });
    });

    this.summaryInfo.bulletPoints = [...bulletPoints];
    this.summaryZHInfo.bulletPoints = [...bulletPointsZH];
    this.summaryFundInfo.bulletPoints = [...bulletPointsFund];
  }

  public setPaperIdFilter(ids: string[]) {
    this.paperIdFilter = ids;
  }

  public updateExpensionText(key: number, text: string) {
    const bulletPoints = this.summaryInfo.bulletPoints;
    bulletPoints.forEach((item) => {
      if (item.key === key) {
        item.expensionPopoverList = handlePopoverContentExtension(text, [
          ...this.paperInfo.papers,
          ...this.paperZHInfo.papers,
          ...this.fundInfo.papers,
        ]);
      }
    });

    const bulletPointsZH = this.summaryZHInfo.bulletPoints;
    bulletPointsZH.forEach((item) => {
      if (item.key === key) {
        item.expensionPopoverList = handlePopoverContentExtension(text, [
          ...this.paperInfo.papers,
          ...this.paperZHInfo.papers,
          ...this.fundInfo.papers,
        ]);
      }
    });

    const bulletPointsFund = this.summaryFundInfo.bulletPoints;
    bulletPointsFund.forEach((item) => {
      if (item.key === key) {
        item.expensionPopoverList = handlePopoverContentExtension(text, [
          ...this.paperInfo.papers,
          ...this.paperZHInfo.papers,
          ...this.fundInfo.papers,
        ]);
      }
    });

    this.summaryInfo.bulletPoints = [...bulletPoints];
    this.summaryZHInfo.bulletPoints = [...bulletPointsZH];
    this.summaryFundInfo.bulletPoints = [...bulletPointsFund];
  }

  public updateResponsePedia(papers: any[]) {
    const processedPapers = papers;
    const processedMap = new Map(
      processedPapers.map((item) => [item.id, item])
    );
    const enPapers = this.paperInfo.papers;
    enPapers.forEach((item) => {
      // @ts-ignore
      if (processedMap.has(item.id)) {
        // @ts-ignore
        const thePaper = processedMap.get(item.id);
        for (const key in thePaper) {
          if (thePaper.hasOwnProperty(key)) {
            item[key] = thePaper[key];
          }
        }
      }
    });
    const zhPapers = this.paperZHInfo.papers;
    zhPapers.forEach((item) => {
      // @ts-ignore
      if (processedMap.has(item.id)) {
        const thePaper = processedMap.get(item.id);
        for (const key in thePaper) {
          if (thePaper.hasOwnProperty(key)) {
            item[key] = thePaper[key];
          }
        }
      }
    });
    const fundPapers = this.fundInfo.papers;
    fundPapers.forEach((item) => {
      // @ts-ignore
      const thePaper = processedMap.get(item.id);
      for (const key in thePaper) {
        if (thePaper.hasOwnProperty(key)) {
          item[key] = thePaper[key];
        }
      }
    });
    this.paperInfo.papers = [...enPapers];
    this.paperZHInfo.papers = [...zhPapers];
    this.fundInfo.papers = [...fundPapers];
  }

  public *fetchResponsePedia() {
    const queryZh =
      this.paperInfo.queryZh ||
      this.paperZHInfo.queryZh ||
      this.fundInfo.queryZh;
    const fetchList = [];
    this.showPapers.forEach((element) => {
      if (element.response) {
        return;
      }
      fetchList.push(element);
    });
    if (fetchList.length === 0) {
      return;
    }
    const res = yield getResponsePedia({
      queryZh,
      papers: fetchList,
    });
    if (!res.ok) {
      return;
    }
    const { papers } = yield res.json();
    const processedPapers = papers;
    const processedMap = new Map(
      processedPapers.map((item) => [item.id, item])
    );
    const enPapers = this.paperInfo.papers;
    enPapers.forEach((item) => {
      // @ts-ignore
      if (processedMap.has(item.id)) {
        // @ts-ignore
        item.response = processedMap.get(item.id).response;
      }
    });
    const zhPapers = this.paperZHInfo.papers;
    zhPapers.forEach((item) => {
      // @ts-ignore
      if (processedMap.has(item.id)) {
        // @ts-ignore
        item.response = processedMap.get(item.id).response;
      }
    });
    const fundPapers = this.fundInfo.papers;
    fundPapers.forEach((item) => {
      // @ts-ignore
      if (processedMap.has(item.id)) {
        // @ts-ignore
        item.response = processedMap.get(item.id).response;
      }
    });
    this.paperInfo.papers = [...enPapers];
    this.paperZHInfo.papers = [...zhPapers];
    this.fundInfo.papers = [...fundPapers];
  }

  public *fetchRelatedSearch() {
    try {
      this.isLoadingFaq = true;
      const summary =
        this.summaryInfo.summary ||
        this.summaryZHInfo.summary ||
        this.summaryFundInfo.summary;
      const queryZh =
        this.paperInfo.queryZh ||
        this.paperZHInfo.queryZh ||
        this.fundInfo.queryZh;
      const listRes = yield getRelatedSearch({
        answer: summary,
        queryZh: queryZh,
      });
      if (!listRes.ok) {
        return;
      }
      const data = (yield listRes.json()) as string[];
      this.queryContext.relatedSearch = data;
    } catch (e) {
    } finally {
      this.isLoadingFaq = false;
    }
  }

  public *fetchSummaryBulletPoints() {
    let { queryZh, papers } = this.paperInfo;
    if (this.mode === 'zh-cn') {
      queryZh = this.paperZHInfo.queryZh;
      papers = this.paperZHInfo.papers;
    }
    if (this.mode === 'fund') {
      queryZh = this.fundInfo.queryZh;
      papers = this.fundInfo.papers;
    }
    this.isLoadingSummaryBulletPoints = true;
    try {
      const res = yield getSummaryBulletPoints({
        papers,
        queryZh,
        rawQuery: this.question,
      });
      if (!res.ok) {
        return;
      }
      const data = (yield res.json()) as {
        refs: string;
        summary: string;
        title: string;
      }[];
      const formattedBulletPoints = initPopoverContent(data, [...papers]);
      switch (this.mode) {
        case 'zh-cn':
          this.summaryZHInfo.bulletPoints = formattedBulletPoints;
          break;
        case 'en':
          this.summaryInfo.bulletPoints = formattedBulletPoints;
          break;
        case 'fund':
          this.summaryFundInfo.bulletPoints = formattedBulletPoints;
          break;
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoadingSummaryBulletPoints = false;
    }
  }

  public *fetchSummaryAnalysis() {
    let { queryZh, papers } = this.paperInfo;
    if (this.mode === 'zh-cn') {
      queryZh = this.paperZHInfo.queryZh;
      papers = this.paperZHInfo.papers;
    }
    if (this.mode === 'fund') {
      queryZh = this.fundInfo.queryZh;
      papers = this.fundInfo.papers;
    }
    try {
      this.isLoadingSummaryAnalysis = true;
      const res = yield getSummaryAnalysis({
        papers,
        queryZh,
        rawQuery: this.question,
      });
      if (!res.ok) {
        return;
      }
      const data = yield res.json();
      switch (this.mode) {
        case 'zh-cn':
          this.summaryZHInfo.bulletPointsPrefix = data;
          break;
        case 'en':
          this.summaryInfo.bulletPointsPrefix = data;
          break;
        case 'fund':
          this.summaryFundInfo.bulletPointsPrefix = data;
          break;
        default:
          this.summaryZHInfo.bulletPointsPrefix = data;
          break;
      }
    } catch (error) {
    } finally {
      this.isLoadingSummaryAnalysis = false;
    }
  }

  public *fetchSummaryBackground() {
    this.isLoadingSummaryBackground = true;
    try {
      const res = yield getSummaryBackground({
        query: this.question,
      });
      if (!res.ok) {
        return;
      }
      const data = yield res.json();

      this.queryContext.background = data;
    } catch (error) {
    } finally {
      this.isLoadingSummaryBackground = false;
    }
  }

  public *fetchSummaryQueryTerms() {
    this.isLoadingSummaryQueryTerms = true;
    try {
      const res = yield getSummaryQueryTerms({
        query: this.question,
      });
      if (!res.ok) {
        return;
      }
      const data = yield res.json();
      this.queryContext.terms = data;
    } catch (error) {
    } finally {
      this.isLoadingSummaryQueryTerms = false;
    }
  }

  public *fetchSummaryConcept() {
    this.isLoadingSummaryConcept = true;
    try {
      const res = yield getSummaryConcept({
        query: this.question,
      });
      if (!res.ok) {
        return;
      }
      const data = yield res.json();
      this.queryContext.concept = data;
    } catch (error) {
    } finally {
      this.isLoadingSummaryConcept = false;
    }
  }

  public *fetchFund() {
    this.isLoadingFundPapers = true;
    try {
      const res = yield getFund({
        query: this.question,
        filter: {
          yearFrom: this.searchFilter.yearFrom,
          fundCategory: this.searchFilter.fundCategory,
          projectCategory: this.searchFilter.projectCategory,
          subject: this.searchFilter.subject,
        },
      });
      if (!res.ok) {
        return;
      }
      const data = yield res.json();
      this.fundInfo = data;
    } catch (e) {
    } finally {
      this.isLoadingFundPapers = false;
    }
  }

  public *fetchZHPapers() {
    this.isLoadingZhPapers = true;
    try {
      const listRes = yield getPartPedia({ query: this.question }, 'zh-cn');
      if (!listRes.ok) {
        return;
      }

      const data = yield listRes.json();
      this.paperZHInfo = {
        queryZh: data.queryZh,
        papers: data.papers.map((item) => {
          item.selected = false;
          return item;
        }),
      };
    } catch (error) {
    } finally {
      this.isLoadingZhPapers = false;
    }
  }

  public *fetchEnPapers() {
    this.isLoadingEnPapers = true;
    try {
      const listRes = yield getPartPedia({ query: this.question }, 'en');
      if (!listRes.ok) {
        return;
      }

      const data = yield listRes.json();
      this.paperInfo = {
        queryZh: data.queryZh,
        papers: data.papers.map((item) => {
          item.selected = false;
          return item;
        }),
      };
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoadingEnPapers = false;
    }
  }

  public *initfetchSearchPapers() {
    switch (this.mode) {
      case SearchMode.ZH_CN:
        yield this.fetchZHPapers();
        break;
      case SearchMode.EN:
        yield this.fetchEnPapers();
        break;
      case SearchMode.FUND:
        yield this.fetchFund();
        break;
      default:
        return;
    }

    this.isInitialed = true;
    this.fetchSummaryAnalysis();
    this.fetchResponsePedia();
    this.fetchSummaryBulletPoints();
    this.fetchRelatedSearch();
  }

  public *fetchSearchPapers() {
    switch (this.mode) {
      case SearchMode.ZH_CN:
        if (this.isLoadingEnPapers || this.paperZHInfo.papers.length > 0) {
          return;
        }
        yield this.fetchZHPapers();
        break;
      case SearchMode.EN:
        if (this.isLoadingZhPapers || this.paperInfo.papers.length > 0) {
          return;
        }
        yield this.fetchEnPapers();
        break;
      case SearchMode.FUND:
        if (this.isLoadingFundPapers || this.fundInfo.papers.length > 0) {
          return;
        }
        yield this.fetchFund();
        break;
      default:
        return;
    }
    this.fetchSummaryAnalysis();
    this.fetchResponsePedia();
    this.fetchSummaryBulletPoints();
  }

  public *fetchFilterFundPapers() {
    yield this.fetchFund();
    yield this.fetchResponsePedia();
  }

  public killFundPapers() {
    this.fundInfo = {
      papers: [],
      queryZh: '',
    };
  }
}

const mainState = new MainState();

export default mainState;
